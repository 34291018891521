@import '../../base';





div.data-validation-view {


    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    flex: 1;

    overflow: hidden;

    position: relative;




    div.control-panel {

        flex: 0;
        overflow: visible;

        width: 50%;
    
        // height: 100%;
        // overflow: hidden;
    
        // box-sizing: border-box;
    
        margin: 30px 0px 0px 0px;
    
        display: flex;
        flex-flow: column;
        // justify-content: flex-start;
    
        background-color: rgba(0,0,0,0.05);
        border-radius: 5px;
    
        // max-width: 50vw;
        // max-width: 1000px;
        // margin: auto;
        // margin: 0px auto;
        // margin: 30px auto auto auto;
    
        padding: 14px;

        div.row {

            display: flex;
            flex-flow: row;

            // border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 0px;
            margin-bottom: 8px;

            &:last-child {
                border: none;
                margin-bottom: 0px;
            }

            div.cell {
                display: flex;
                flex-flow: row;
                align-items: center;

                // line-height: 20px;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0,0,0,0.7);

                padding: 4px 0px;
                margin: 0px 4px;

                // background-color: rgba(255,255,255,1.0);
                // border: 1px solid rgba(0,0,0,0.15);
                // border-radius: 3px;

                // border: 1px solid red;

                &.grow {
                    flex: 1
                }

                &.label {
                    margin-right: 10px;
                }

                &.state {
                    display: flex;
                    flex-flow: row;

                    span.state {
                        background-color: white;
                        height: 22px;
                        padding: 0px 4px 0px 4px;
                        border-radius: 3px;
                        border: 1px solid rgba(0,0,0,0.15);
                        display: flex;
                        flex-flow: row;
                        align-items: center;

                        &.PENDING {
                            background-color: rgba(255,255,255,0.7);
                            color: rgba(0,0,0,0.4)
                        }

                        &.RUNNING {
                            background-color: rgba(255,255,255,0.8);
                            color: rgba(0,0,0,0.7);
                            color: #006400;
                        }

                        &.CANCELED {
                            background-color: rgba(255,255,255,0.7);
                            // color: #cf000f;
                            color: rgba(207, 0, 15, 0.8);
                            border-color: rgba(0,0,0,0.08)
                        }

                        &.FINISHED {
                            color: #006400;
                        }

                        &.ERROR {
                            background-color: white;
                            color: #cf000f;
                            border-color: rgba(0,0,0,0.3)
                        }

                        .inline-button {
                            padding: 0px;
                            border-radius: 3px;
                            margin: 0px;
                            margin-left: 2px;
                            margin-right: -2px;
                            height: 18px;
                            width: 18px;
                            padding: 1px;
                            border: 1px solid transparent;

                            // background-color: rgba(0,0,0,0.08);
                            background-color: unset;

                
                            &:hover {
                                // background-color: rgba(9, 30, 66, 0.13);
                                // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                background-color: rgba(0,0,0,0.1);
                                border: 1px solid rgba(0,0,0,0.1);
                            }
                
                            & svg {
                                font-size: 22px;
                            }
        
                            &.cancel-button {
                                color: #cf000f;
                            }
        
                            &.restart-button {
                                color: #006400;
                            }
                        }
        
                    }
                }

                &.progress {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    border: none;

                    div.progress-bar-container {
                        display: flex;
                        flex-flow: row;
                        align-items: center;
                        justify-content: center;

                        position: relative;
                        padding: 0px;
                        height: 18px;
                        width: 100%;

    
                        span.progress-count {
                            // display: none;
                            font-size: 11px;
                            font-weight: bold;
                            // margin-top: 4px;
                            // margin-bottom: -2px;
                            z-index: 1;
                            color: white;
                        }

                        .progress-bar {
                            // border: 1px solid red;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            // height: 8px;
                            border-radius: 3px;
                            .MuiLinearProgress-bar {
                                background-color: #0B4F6C;
                                border-radius: 2px;
                            }
                        }
                    }

                }





            }


        }






    }
















    div.grid-container {
        flex: 1;
    }


    








}





div.error-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(0,0,0,0.1);
    border: none;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(255,255,255,1.0);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


    /*
    h1 {
        font-size: 13px;
        margin: 0px;
    }
    span {
        font-size: 13px;
    }
    */

    // copy paste vom ErrorHandler.scss
    // TODO: EINE EINZELNEN KOMPONENTE FÜR ERROR RENDERING, DIE AN ALLEN STELLEN VERWENDET WERDEN KANN WO BENÖTIGT

    div.error-view {
        /* background-color: white; */
        flex: 1;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    div.error-view > h1 {
        margin: 0px;
        padding: 0px;
        font-family: monospace;
        font-size: 16px;
        color: black;
        margin-bottom: 8px;
    }

    div.error-view > div.error {
        font-family: monospace;
        font-size: 14px;
        margin-bottom: 8px;
        color: black;
    }

    div.error-view > div.error > span.name {
        font-weight: bold;
    }

    div.error-view > div.error > span.message {
        font-weight: normal;
    }

    div.error-view > table.stack-trace {
        /*
        background-color: #666;
        color: white;
        */
        background-color: #DDD;
        color: #666;

        border-radius: 3px;
        font-size: 10px;
        font-family: monospace;
        /* font-weight: bold; */
        padding: 8px;
    }

    /*
    div.error-view > table.stack-trace td.function {
    }
    */

    div.error-view > table.stack-trace td.file {
        padding-left: 8px;
    }

    div.error-view > table.stack-trace td.file a {
        color: #114aaf;
    }

    div.error-view > table.stack-trace td.file a span.location {
        color: #107ce9;
    }


    div.error-view > span.message {
        font-weight: bold;
        color: red;
        font-size: 14px;
        font-family: monospace;
    }





}

