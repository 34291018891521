


div.processing-log {

    &.error {
        flex-flow: row;
    }

    flex: 1;
    overflow: hidden;

    width: 50%;

    // height: 100%;
    // overflow: hidden;

    // box-sizing: border-box;

    margin: 20px 0px 30px 0px;

    display: flex;
    flex-flow: column;
    // align-items: center;
    align-items: flex-start;

    background-color: rgba(0,0,0,0.05);
    border-radius: 5px;

    // max-width: 50vw;
    // max-width: 1000px;
    // margin: auto;
    // margin: 0px auto;
    // margin: 30px auto auto auto;

    padding: 14px;        

    div {
        margin: 10px;
    }

    div.exception-vector {
        img.exception-vector {
            width: 300px;
            // border: 1px solid blue;
        }
    }

    font-size: 13px;
    // font-weight: bold;
    color: rgba(0,0,0,0.7);

    div.box {

        overflow: visible;

        &.scroll {
            overflow-y: auto;
            overflow-x: hidden;
        }

        align-self: stretch;
        border: 1px solid rgba(0,0,0,0.1);
        background-color: rgba(255,255,255,0.9);
        padding: 20px;
        border-radius: 3px;

        div.entry-detail {
            display: flex;
            flex-flow: column;
            margin-left: 30px;
            margin-bottom: 20px;
            // border: 2px solid green;
        }

        * {
            // border: 1px solid red;
        }

        p {
            margin: 0px;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0px;
            }
        }

        div.label {
            margin: 0px;
            font-weight: bold;
            margin-bottom: 16px;
            // border: 1px solid red;
        }

        div.error,
        div.success {
            &.success {
                color: #006400;
            }
            // border: 2px dashed blue;
            & > * {
                // border: 1px solid green;
            }
            svg {
                height: 16px;
                width: 16px;
                min-width: 16px;
                min-height: 16px;
                margin-right: 4px;
            }
            // border: 1px solid blue;
            margin: 0px;
            margin-left: 30px;
            margin-bottom: 12px;
            color: rgba(207, 0, 15, 0.8);
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            line-height: 16px;
        }

    }



    div.error {
        padding: 0px;
        margin: 0px;

        // border: 2px solid green;
        background-color: rgba(0,0,0,0.08);
        border: 1px solid rgba(0,0,0,0.08);
        border-radius: 5px;
        padding: 8px 8px 8px 4px;

        & > span > div.cause {
            color: rgba(0,0,0,0.6);
            margin: 10px 0px 0px 0px;

            // border: 1px solid blue;

            border-radius: 3px;
            border: 1px solid rgba(0,0,0,0.2);
            background-color: rgba(255,255,255,0.9);
            padding: 4px;
        }

        & > button {
        
            background-color: rgba(0,0,0, 0.05);

            margin: 0px;
            margin-top: 20px;

            padding: 4px 10px;
            border-radius: 3px;

            line-height: 1.0;

            &:hover {
                background-color: rgba(9, 30, 66, 0.13);
            }

            & > .MuiButton-startIcon > svg,
            & > .MuiButton-endIcon > svg {
                font-size: 22px;
            }
        }
    }




}
