
/*
    UPDATE
    Anstatt fonts selbst runterzuladen, zu hosten und über @font-face zu definieren, kann einfach font-source verwendet werden.
    https://fontsource.org/docs/getting-started 
*/

/*
    TEMPLATE
    falls fonts nicht über font-source installiert werden können

@font-face {
    font-family:'IBM Plex Sans';
    src: url("./fonts/IBMPlexSans/IBMPlexSans-Thin.ttf") format('truetype');
    font-weight:100;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'IBM Plex Sans';
    src: url("./fonts/IBMPlexSans/IBMPlexSans-ExtraLight.ttf") format('truetype');
    font-weight:200;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'IBM Plex Sans';
    src: url("./fonts/IBMPlexSans/IBMPlexSans-Light.ttf") format('truetype');
    font-weight:300;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'IBM Plex Sans';
    src: url("./fonts/IBMPlexSans/IBMPlexSans-Regular.ttf") format('truetype');
    font-weight:400;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'IBM Plex Sans';
    src:
        url("./fonts/IBMPlexSans/IBMPlexSans-Medium.ttf") format('truetype');
    font-weight:500;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'IBM Plex Sans';
    src: url("./fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf") format('truetype');
    font-weight:600;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'IBM Plex Sans';
    src: url("./fonts/IBMPlexSans/IBMPlexSans-Bold.ttf") format('truetype');
    font-weight:700;
    font-style:normal;
    font-display:swap;
}


@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-Thin.ttf") format('truetype');
    font-weight:100;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-ExtraLight.ttf") format('truetype');
    font-weight:200;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-Light.ttf") format('truetype');
    font-weight:300;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-Regular.ttf") format('truetype');
    font-weight:400;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-Medium.ttf") format('truetype');
    font-weight:500;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-SemiBold.ttf") format('truetype');
    font-weight:600;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-Bold.ttf") format('truetype');
    font-weight:700;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-ExtraBold.ttf") format('truetype');
    font-weight:800;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:'Raleway';
    src: url("./fonts/Raleway/Raleway-Black.ttf") format('truetype');
    font-weight:900;
    font-style:normal;
    font-display:swap;
}

*/




// Hier der Import der fonts, die über fontsource geladen werden 

// Anscheinend müssen alles weight varianten explizit geladen werden. Wenn man @import '@fontsource/roboto' verwendet bekommt man nur 400
@import '@fontsource/roboto/300';           
@import '@fontsource/roboto/400';
@import '@fontsource/roboto/500';
@import '@fontsource/roboto/700';




$MAIN_MENU_FONT: 500 14px/24.5px 'Roboto';
$DIALOG_HEADER_FONT: 700 0.875rem 'Ubuntu';
$SIMPLE_LIST_FONT: 400 14px 'Ubuntu';
$H1_FONT: 700 28px 'Ubuntu';
$H2_FONT: 700 18px 'Roboto';

$AGGRID_HEADER_FONT: 700 13px 'Roboto';
$AGGRID_CELL_FONT: 400 12px 'Ubuntu';


h1 {
    font: $H1_FONT;
    // margin: 0px;
}

h2 {
    font: $H2_FONT;
    // margin: 0px;
}

div.ag-header-cell,
div.ag-header-group-cell {
    font: $AGGRID_HEADER_FONT !important;
}

div.ag-cell {
    font: $AGGRID_CELL_FONT;
}


















