





div.page-content {
    
    border-radius: 4px;
    margin: 10px;
    padding: 30px 30px;
    height: 100%;
    display: flex;
    flex-flow: column;
    background-color: white;
    
    p {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }        

    h1, h2 {
        margin-top: 0px;
        color: rgba(0,0,0,0.75)
    }

    .link {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-top: 8px;
        margin-left: 20px;
        font-weight: normal;
        text-decoration: none;
        color: #1976d2;

        .icon {
            margin-right: 4px;
        }
    }

}