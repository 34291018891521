

div.error-message {
    font-weight: bold;
    padding: 4px 8px;
    color: rgba(255,255,255,0.9);
    background-color: #cf000f;
    border: 1px solid rgba(0,0,0,0.2);
    margin: 10px 0px;
    border-radius: 5px;
}