
@import '../base';



/* hack.. muss woanders richtig definiert werden */
.MuiDialogContent-root {
    padding: 0px !important;
}



div.abc {
    background-color: $BGCOLOR_A;
    position: absolute;
    top: 0px;
    left: 200px;
    height:32px;
    width: 500px;
}


main.page-content > div.grid-container {
    height: 100%;

    padding: 10px;

    & div.ag-root-wrapper {
        border: none;
        border-radius: 4px;
        // border: 1px solid rgba(0,0,0,0.4);
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    /*
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border: 1px solid #AAA;
    */
}














/* ENUM CELL VALUES */

div.ag-theme-alpine-modified div.enum-value-renderer > span.enum-value {

    border-radius: 3px; 
    padding: 2px 5px;
    font-weight: bold;

    // border: 1px dashed rgba(0,0,0,0.3);
    border: 1px solid rgba(0,0,0,0.2);
    background-color: rgba(0,0,0,0.15);
    

    /*
    border-radius: 3px;
    padding: 2px 4px;
    margin-right: 4px;
    font-weight: bold;

    // border-radius: 2px;
    // padding: 1.3px 2.5px;
    // font-weight: bold;
    // margin-right: 4px;

    // border: 1px solid rgba(0,0,0,0.1);
    // background-color: rgba(0,0,0,0.1);

    color: white;

    border: 1px solid rgba(0,0,0,0.45);

    // background-color: rgba(0,0,0,0.35);
    // background-color: #796465;
    // background-color: #6A2E35;
    // background-color: #556F44;
    // background-color: #344966;
    // background-color: #B4CDED;
    // background-color: #276FBF;
    // background-color: #364156;
    // background-color: #087E8B;
    // background-color: #84ace1;
    background-color: #5b91d7;
    */


}

div.ag-theme-alpine-modified div.enum-value-renderer > span.enum-value:last-child {
    margin-right: 0px;
}




/* genomic position */


div.variant-view span.cell_value_genomic_position,
div.ag-theme-alpine-modified span.cell_value_genomic_position {

    border-radius: 5px; 
    padding: 3px 5px;
    font-weight: bold;
    border: 1px solid rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.15);

    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    /*
    * {
        margin-right: 4px;
        border-radius: 3px; 
        padding: 2px 5px;
        font-weight: bold;
        border: 1px dashed rgba(0,0,0,0.3);
        background-color: rgba(0,0,0,0.15);
    }

    *:last-child {
        margin-right: 0px;
    }
    */

    span.separator {
        // display: none;
        border-left: 1px solid rgba(0,0,0,0.2);
        width: 0px;
        margin: 0px 6px;
    }

    span.build {
        color: #004E64;
    }


    span.chr {
        color: #004E64;
    }

    span.pos {
        color:#5E0035;
    }

    span.ref {
        color: #388659;
    }
    
    span.alt {
        color: #388659;
    }


}

div.ag-theme-alpine-modified div.ag-row.expanded span.cell_value_genomic_position {

    * {
        color: white;
    }

    span.build {
    }

    span.chr {
        
    }

    span.pos {
    }

    span.ref {
    }
    
    span.alt {
    }

}






/* ACMG Class */

div.ag-theme-alpine-modified div.enum-value-renderer.COLUMN_acmgClass > span.enum-value {

    color: white;

    &.VALUE-benign {
        background-color: rgb(7, 100, 24);
    }

    &.VALUE-likelybenign {
        background-color: rgb(80, 168, 56);
    }

    &.VALUE-unclear {
        background-color: rgb(255, 171, 69);
    }

    &.VALUE-likelypathogenic {
        background-color: rgb(235, 91, 43);
    }

    &.VALUE-pathogenic {
        background-color: rgb(199, 7, 0);
    }
}



/* ACMG Criteria */

div.ag-theme-alpine-modified div.enum-value-renderer.COLUMN_acmgCriteria > span.enum-value {
    
    margin-right: 4px;

    &:last-child {
        margin-right: 4px;
    }

    &.VALUE-nospecificationpossible {
        // background: rgba(0,0,0,0.1);
        // border-color: rgba(0,0,0,0.1);

        /*
        color: rgba(0,0,0,0.5);
        border: 1px solid rgba(0,0,0,0.15);
        background-color: rgba(0,0,0,0.1);
        */
        
        // color: white;
    }

    &.VALUE-PVS1 {
        background-color: rgb(199, 7, 0);
    }

    &.VALUE-PS1,
    &.VALUE-PS2,
    &.VALUE-PS3,
    &.VALUE-PS4 {
        background-color: rgb(217, 49, 21);
    }

    &.VALUE-PM1,
    &.VALUE-PM2,
    &.VALUE-PM3,
    &.VALUE-PM4,
    &.VALUE-PM5,
    &.VALUE-PM6 {
        background-color: rgb(235, 91, 43);
    }

    &.VALUE-PP1,
    &.VALUE-PP2,
    &.VALUE-PP3,
    &.VALUE-PP4,
    &.VALUE-PP5 {
        background-color: rgb(253, 133, 64);
    }

    &.VALUE-BP1,
    &.VALUE-BP2,
    &.VALUE-BP3,
    &.VALUE-BP4,
    &.VALUE-BP5,
    &.VALUE-BP6,
    &.VALUE-BP7 {
        background-color: rgb(80, 168, 56);
    }

    &.VALUE-BS1,
    &.VALUE-BS2,
    &.VALUE-BS3,
    &.VALUE-BS4 {
        background-color: rgb(37, 136, 49);
    }

    &.VALUE-BA1 {
        background-color: rgb(7, 100, 24);
    }

    // in varsome gibt es noch eine sehr hellgrüne farbe: rgb(115, 195, 111)
    // ist mir aber nicht klar, für was das steht

    /*

    &.VALUE-PVS1,
    &.VALUE-PP5 {
        // background-color: rgb(199, 7, 0);
    }

    &.VALUE-PS1,
    &.VALUE-PS2,
    &.VALUE-PS3,
    &.VALUE-PS4,
    &.VALUE-PM1,
    &.VALUE-PM5,
    &.VALUE-PP3 {
    }

    &.VALUE-PM3,
    &.VALUE-PM4,
    &.VALUE-PM6 {
    }

    &.VALUE-PM2,
    &.VALUE-PP1,
    &.VALUE-PP2,
    &.VALUE-PP4 {

    }
    
    &.VALUE-BP1,
    &.VALUE-BP2,
    &.VALUE-BP3,
    &.VALUE-BP4,
    &.VALUE-BP5,
    &.VALUE-BP6 {

    }

    &.VALUE-BS1,
    &.VALUE-BS2,
    &.VALUE-BS3,
    &.VALUE-BS4 {
        rgb(80, 168, 56)
    }

    &.VALUE-BA1 {
        background-color: rgb(7, 100, 24);
    }

    */

}











/*
    PVS1    pathogenic very strong          ganz dunkles rot (very strong)

    PS1     pathogenic strong               dunkles rot in varsome (strong)
    PS2     pathogenic strong               dunkles rot in varsome (strong)
    PS3     pathogenic strong               dunkles rot in varsome (strong)
    PS4     pathogenic strong               dunkles rot in varsome (strong)

    PM1     pathogenic moderate             dunkles rot (strong)
    PM2     pathogenic moderate             helles orange (supporting)
    PM3     pathogenic moderate             dunkles orange (moderate)
    PM4     pathogenic moderate             dunkles orange (moderate)
    PM5     pathogenic moderate             dunkles rot (strong)
    PM6     pathogenic moderate             dunkles orange (moderate)

    PP1     pathogenic supporting           helles orange (supporting)
    PP2     pathogenic supporting           helles orange (supporting)
    PP3     pathogenic supporting           dunkles rot (strong)
    PP4     pathogenic supporting           helles orange (supporting)
    PP5     pathogenic supporting           ganz dunkles rot (very strong, WIESO?)

    BP1     benign supporting           alle die gleiche farbe bei varsome (das leichte grün)
    BP2     benign supporting
    BP3     benign supporting
    BP4     benign supporting
    BP5     benign supporting
    BP6     benign supporting
    BP7     benign supporting

    BS1     benign strong               alle die gleiche farbe bei varsome (das dunklere grün)
    BS2     benign strong
    BS3     benign strong
    BS4     benign strong

    BA1     benign stand alone (das höchste level an benign)           das ganz dunkle grün





    PVS1

    PS1
    PS2
    PS3
    PS4

    PM1
    PM2
    PM3
    PM4
    PM5
    PM6

    PP1
    PP2
    PP3
    PP4
    PP5

    BP1
    BP2
    BP3
    BP4
    BP5
    BP6
    BP7

    BS1
    BS2
    BS3
    BS4

    BA1

*/









div.variant-links {
    display: flex;
    flex-flow: row;
    height: 40px;
    align-items: center;

    & > * {
        margin-left: 8px;
    }

    & > *:first-of-type {
        margin-left: 0px;
    }
}









div.ag-row {

    & > div.test-alex {
        width: 100%;
        border: 1px solid red;
        top: 10px;
    }


}







/* Variant Genes */

div.variant-grid-gene-detail-view {
    padding: 16px 16px;
    width: 350px;
    font-size: 14px;
    position: relative;
    background-color: rgba(0,0,0,0.05);
    color: rgba(0,0,0,0.7);

    & > p {

        background-color: rgba(0,0,0,0.05);
        border-radius: 3px;
        border: 1px solid rgba(0,0,0,0.1);

        margin: 0px;
        margin-bottom: 8px;
        padding: 8px;

        & span.symbol-name {
            font-size: 1.2em;
            font-weight: bold;
        }

        & span.hgnc-tag {
            font-size: 0.95em;
            font-weight: bold;
            color: white;
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: #fab700;
            padding: 2px 4px;
            border-radius: 3px;
            border: 1px solid rgba(0,0,0,0.1);
        }

        & span.label {
            font-weight: bold;
        }

        & a {
            color: #1976d2 !important;
            text-decoration: none;
            display: inline-flex;
            flex-flow: row;
            padding: 2px;
            border: 1px solid transparent;
            background-color: none;
            margin-top: 4px;
            margin-left: -4px;
            margin-bottom: -4px;
            & > .icon {
                margin-left: 4px;
            }
            &:hover {
                border-radius: 3px;
                border: 1px solid rgba(0,0,0,0.1);
                background-color: rgba(0,0,0,0.1);
            }
        }


        div.occurrence {
            color: rgba(0,0,0,0.3);
            font-weight: bold;
            font-size: 0.8em;
            margin-bottom: 12px;
        }

        span.gene-pos {

            border-radius: 5px; 
            padding: 3px 5px;
            font-weight: bold;
            border: 1px solid rgba(0,0,0,0.4);
            background-color: rgba(0,0,0,0.15);
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

            span.separator {
                // display: none;
                border-left: 1px solid rgba(0,0,0,0.2);
                width: 0px;
                margin: 0px 6px;
            }
        
            span.build {
                color: #004E64;
            }
        
            span.chr {
                color: #004E64;
            }
        
            span.start {
                color:#5E0035;
            }

            span.end {
                color:#388659;
            }
        }


        div.section {
            background-color: rgba(255,255,255,0.3);
            margin-bottom: 12px;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            padding: 8px;
            border-radius: 3px;

            & > span.label {
                margin-bottom: 4px;
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }
    

        &:last-child {
            margin-bottom: 0px;
        }
    }


}

div.ag-theme-alpine-modified div.variant-genes {

    & span.variant-gene {
        border-radius: 3px; 
        padding: 1px 3px;
        font-weight: bold;
        border: 1px solid rgba(0,0,0,0.4);
        background-color: rgba(0,0,0,0.1);
        color: rgba(0,0,0,0.65);
    
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        cursor: pointer;

        transition: border 0.2s, background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    & span.variant-gene:hover {
        border: 1px solid rgba(0,0,0,0.6);
        background-color: rgba(255,255,255,0.4);
        color: rgba(0,0,0,0.9);
    }

    & > .variant-gene-container {
        margin-right: 8px;
    }

    & > .variant-gene-container:last-child {
        margin-right: 0px;
    }

}










div.grid-container + div.grid-row-details {
    // border: 2px solid red;
    // box-sizing: border-box;

    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.0;

    backdrop-filter: blur(2px);
    background-color: rgba(255,255,255,0.2);

    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    // default: enter right
    &, &.enter-right {
        transition: right 0.4s, bottom 0.4s, left 0.4s, top 0.4s, opacity 0.4s;
        top: 0px;
        left: 100%;
        opacity: 0.0;
        &.opened {
            left: 0px;
            opacity: 1.0;
        }
        &.closed {
            left: 100%;
            opacity: 0.0;
        }
    }

    &.enter-left {
        transition: right 0.4s, bottom 0.4s, left 0.4s, top 0.4s, opacity 0.4s;
        top: 0px;
        left: -100%;
        opacity: 0.0;
        &.opened {
            left: 0px;
            opacity: 1.0;
        }
        &.closed {
            left: -100%;
            opacity: 0.0;
        }
    }

    &.enter-top {
        transition: right 0.4s, bottom 0.4s, left 0.4s, top 0.4s, opacity 0.4s;
        top: -100%;
        left: 0px;
        opacity: 0.0;
        &.opened {
            top: 0px;
            opacity: 1.0;
        }
        &.closed {
            top: -100%;
            opacity: 0.0;
        }
    }

    &.enter-bottom {
        transition: right 0.4s, bottom 0.4s, left 0.4s, top 0.4s, opacity 0.4s;
        top: 100%;
        left: 0px;
        opacity: 0.0;
        &.opened {
            top: 0px;
            opacity: 1.0;
        }
        &.closed {
            top: 100%;
            opacity: 0.0;
        }
    }

    &.no-slide {
        transition: opacity 0.4s;
        top: 0px;
        left: 100%;
        opacity: 0.0;
        &.opened {
            left: 0px;
            opacity: 1.0;
        }
        &.closed {
            left: 100%;
            opacity: 0.0;
        }
    }


    & > div.grid-row-details-content {

        display: flex;
        flex-flow: column;

        overflow: hidden;

        height: 100%;
        margin: 50px;
        padding: 0px;
        background-color: white;
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        & > div.header {

            height: 30px;
            background-color: rgba(0,0,0,0.1);

            font-size: 0.8em;
            font-weight: bold;
            color: rgba(0,0,0,0.7);

            display: flex;
            flex-flow: row;
            align-items: center;

            background-color: rgba(0,0,0,0.12);
            border-bottom: 1px solid rgba(0,0,0,0.05);

            & > .close-row-details-button {
                border-radius: 0px;
                width: 30px;
                height: 30px;
                // margin-right: 8px;
                font-size: 1.5em;
                border-left: 1px dashed rgba(0,0,0,0.1);
            }

            & > .label {
                height: 100%;
                // margin-top: 1px;
                padding-left: 10px;

                flex: 1;
                display: flex;
                flex-flow: row;
                align-items: center;
            }
    
        }

    
    }





}














