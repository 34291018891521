





div.file-download-links {
    

    a.file-download-link {
        display: block;
        text-decoration: none;
        font-weight: bold;
        color: #1976d2;
        cursor: pointer;
    }
    
}