

.MuiPopover-root.default-popover-root {

    & > .MuiBackdrop-root {
        backdrop-filter: blur(2px);
        background-color: rgba(255,255,255,0.2);
    }


    & > .MuiPaper-root.default-popover-paper {
        padding: 0px;
        transform: translateY(14px) !important;
        border: 1px solid rgba(0,0,0,0.4);
        border-radius: 3px;
        // position: absolute;
        overflow: visible;
    }

    & > .MuiPaper-root.default-popover-paper > div.icon {
        position: absolute;
        width: 8px;
        height: 8px;
        border: 1px solid rgba(0,0,0,0.4);
        border-right: none;
        border-bottom: none;
        top: -5px;
        left: calc(50% - 6px);
        background-color: white;
        transform: rotate(45deg);
    }

}

