
div.link-container {

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    & .icon.error {
        width: 18px;
        height: 18px;
        color: rgba(207,0,15,0.6);
    }

    a.linkout {

        text-decoration: none;

        padding: 4px;
        margin: 0px 4px;
    
        border-radius: 3px;
        display: flex;
        flex-flow: row;
        align-items: center;
        color: rgba(0,0,0,0.9);
    
        & > span.text {
            line-height: 14px;
        }
    
        & > img.logo {
            height: 14px;
        }
    
        & .icon {
            margin-left: 8px;
            width: 14px;
            height: 14px;
        }
    
        cursor: pointer;

        transition: background-color 0.15s, border 0.15s, box-shadow 0.15s;

        // border: 1px solid rgba(0,0,0,0.1);
        // background-color: rgba(0,0,0,0.1);

        border: 1px solid rgba(0,0,0,0.4);
        // background-color: rgba(0,0,0,0.15);
        background-color: rgba(9, 30, 66, 0.19);
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        &:hover {
            border: 1px solid rgba(0,0,0,0.8);
            background-color: rgba(255,255,255,0.9);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        &:active {
            border: 1px solid rgba(0,0,0,0.6);
            background-color: rgba(255,255,255,1.0);
            box-shadow: inset 0 0 2px;
        }

        &.clinvar >  span.clinvar-logo-text {
            font-weight: bold;
            font-size: 15px;
            color: #333;
            text-shadow: 1px 1px 1px rgba(240,240,240,.9);
            line-height: 14px;
        }
    }


    

}




