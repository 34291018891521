






div.grid-container.scroll {

    // border-radius: 10px;
    // padding: 4px;

    overflow: hidden;
    & > div {
        height: 100%;
        overflow: hidden;
        & > div.ag-root-wrapper {
            height: 100%;
            overflow: hidden;
            & > div.ag-root-wrapper-body {
                height: 100%;
                overflow: hidden;
                & > div.ag-root {
                    height: 100%;
                    overflow: hidden;
                    & > div.ag-body-viewport {
                        overflow-y: auto;
                        padding: 0px;
                    }
                }
            }
        }
    }
}




/* generischer style für ag-grids */

div.ag-theme-alpine-modified {
}

div.ag-theme-alpine-modified div.ag-root-wrapper {
    height: 100%;
    overflow: hidden;
    // border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.2);
}

div.ag-theme-alpine-modified div.ag-header {
    /*
    background-color: #EEE;
    */
}

div.ag-theme-alpine-modified div.ag-header-row {
    padding: 0px 8px;

    & > div.ag-header-cell {
        &:last-child {
            & > div.ag-header-cell-resize {
                display: none;
            }
        }
    }
}

/*
div.ag-theme-alpine-modified div.ag-header-row > div.ag-header-cell:first-of-type {
    margin-left: 40px;
}
*/

div.ag-theme-alpine-modified div.ag-header-container {
    padding: 0px 8px;
}

div.ag-theme-alpine-modified div.ag-body-viewport {
    padding: 8px;
    /*
    background-color: #f1f2eb;
    background-color: #EBF0F2;
    background-color: #EBF0F2;
    background-color: #EBF2F2;
    background-color: #FAFAFA;
    background-color: #eef0f2;
    */
}

div.ag-theme-alpine-modified div.ag-row {
    border-radius: 5px;

    transition: background-color 0.2s, height 0.2s;

    // border: 1px solid transparent;

    border: none;
    // border-bottom: 2px solid transparent;

    border: 1.5px solid white;

    overflow: hidden;


}

div.ag-theme-alpine-modified div.ag-row-even {
    background-color: unset;
}

div.ag-theme-alpine-modified div.ag-row-odd {
    background-color: rgba(0,0,0,0.1);
    background-color: unset;
}

div.ag-theme-alpine-modified div.ag-row-hover {
    background-color: #ecf0f1;
}

div.ag-theme-alpine-modified div.ag-row-selected {
    /* alternativen */
    background-color: #FAE3C8;
    background-color: #DEE2FF;
    background-color: #efd3d7;
    background-color: #cbc0d3;
    background-color: #ffd97d;
    background-color: #ff9b85;
    background-color: #f8dda4;
    background-color: #ddf9c1;
    background-color: #aed9e0;
}



div.ag-theme-alpine-modified {
}


div.ag-theme-alpine-modified .ag-header-cell-resize::after {
    width: 1px;
    height: 50%;
    top: calc((100% - 50%)/2)
}

div.ag-theme-alpine-modified > div.ag-root-wrapper {
    /* border: none; */
}

div.ag-theme-alpine-modified div.ag-header {
}

div.ag-theme-alpine-modified div.ag-row {
}

div.ag-theme-alpine-modified span.ag-cell-value {
        
}

div.ag-theme-alpine-modified div.ag-row div.ag-cell {

    height: unset;
    min-height: 40px;

    display: flex;

    border: none;
    border-right: 1px dashed rgba(0,0,0,0.2);
    // border-right: 1px solid rgba(0,0,0,0.1);

    &:last-child {
        border-right: none;
    }


    // border: 1px solid red;
    // background-color: rgba(0,0,255,0.2);


    transition: padding 0.1s;

    // box-sizing: border-box;

    overflow: hidden;
}




div.ag-theme-alpine-modified div.ag-row div.ag-cell div.cell-content > * {
    min-height: inherit;
}

div.ag-theme-alpine-modified div.ag-row div.ag-cell div.cell-content {
    margin: 0px;
    min-height: inherit;
    overflow: visible;
    overflow-x: hidden;

    // width: 100%;
    // background-color: #9ba7c0;

    // position: relative;
}




/* EXPANDED ROWS */

div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row.expanded {



    background-color: #aed9e0;
    background-color: rgba(174, 217, 224, 0.8);
    background-color: #c4e0ed;
    background-color: #f7f3e3;
    background-color: #EACBD2;
    background-color: #F7B538;
    background-color: #DB7C26;
    background-color: #DEBAC0;
    background-color: #8D9EC6;
    background-color: #A06B9A;
    background-color: #EDD382;
    background-color: #F4D35E;
    background-color: #D0CFCF;
    background-color: #AEA4BF;
    background-color: #D5CAD6;
    background-color: #79B473;
    background-color: #D5C6E0;
    background-color: #EAAC8B;
    background-color: #E3E4DB;
    background-color: #90bba4;
    background-color: #71a88a;
    background-color: #66A182;
    background-color: #D8572A;
    background-color: #B8B7B7;
    background-color: #81b197;

    color: white;

    // border: 1px solid rgba(0,0,0,0.05);
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


    // border: 2px solid white;

    // border: 1px solid #8896a2;
    // padding-top: 20px;
    // padding-bottom: 20px;


    & > div.ag-cell {

        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;

        & > div.ag-cell-wrapper {
            font-weight: bold;    
        }

        & > div.cell-content {
            // cell content in expanded row, aber ohne cell expansion content
        }


        & > div.cell-content.row-expansion {
            // cell content mit expansion rows
            // background-color: red;
            width: 100%;
        }

    }


}

div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row.expanded + div.ag-row.expanded {
    // border-top: none;
}

div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row:not(.expanded) {
    /* border: 1px solid green; */
    opacity: 0.2;
    box-sizing: border-box;
}



// div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row.expanded div.ag-cell div.ag-cell-wrapper {
//     font-weight: bold;    
// }

// div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row.expanded div.ag-cell div.ag-cell-wrapper span.ag-cell-value {
//     // background-color:rgba(0,0,0,0.1);
// }

// div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row.expanded div.cell-content {
//     // margin: 8px 0px;
// }

// div.ag-theme-alpine-modified div.has-expanded-rows div.ag-row.expanded * {
//     border: 1px solid red;
//     margin: 2px;
//     padding: 2px;
// }

