




div.add-user-form {
    width: 500px;
    margin: 20px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
}


div.add-user-form > * {
    /* margin: 0px; */
    margin-bottom: 12px;
}

div.add-user-form > *:last-child {
    margin-bottom: 0px;
}



div.add-user-form > div.message {
    font-weight: bold;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
}

div.add-user-form > div.error-message {
    border: none;
    background-color: unset;
    color: #D8000C;
}

div.add-user-form > div.success-message {
    color: #4F8A10;
}


div.add-user-form >  div.lab-select {
    width: 100%;
}




div.users-grid  button.action-button {
    border-radius: 5px;
    background-color: rgba(0,0,0,0.08);
    border: 1px solid transparent;
    margin-right: 4px;
    transition: all 0.3s;
}

div.users-grid  button.action-button:hover {
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



div.registry-info-dialog-content {
    padding: 20px !important;
    font-size: 13px;
}



div.registry-info-dialog-content > table.registry-info-table {
}

div.registry-info-dialog-content > table.registry-info-table td {
    background-color: rgba(0,0,0,0.05);
    padding: 4px 4px;
    border-radius: 3px;
}

div.registry-info-dialog-content > table.registry-info-table td:first-child {
    font-weight: bold;
}





div.reset-password-dialog-content {
    padding: 20px !important;
    display: flex;
    flex-flow: column;
    align-items: center;

}
