



a.breadcrumb-item {
    background-color: transparent;
    /*
    border: 2px solid red;
    */
    border-radius: 0px;
    cursor: pointer;
}