


button.log-error-toggle-button {

    margin: 2px 0px;

    padding: 2px 4px 2px 2px;
    border-radius: 3px;

    line-height: 1.0;

    background-color: rgba(9, 30, 66, 0.13);

    color: rgba(0,0,0,0.5);

    font-size: 0.8em;
    font-weight: bold;
    // text-transform: unset;

    &:hover {
        background-color: rgba(9, 30, 66, 0.13);
    }

    & > .MuiButton-startIcon {
        margin: 0px;
    }

    & > .MuiButton-startIcon,
    & > .MuiButton-startIcon > svg {
        margin-right: 0.0em !important;
    }

    & > .MuiButton-startIcon > svg,
    & > .MuiButton-endIcon > svg {
        margin: 0px;
        width: 16px !important;
        height: 16px !important;
        min-width: 16px !important;
        min-height: 16px !important;
    }



}