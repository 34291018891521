div.error-view {
    /* background-color: white; */
    flex: 1;
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
}

div.error-view > h1 {
    margin: 0px;
    padding: 0px;
    font-family: monospace;
    font-size: 16px;
    color: rgba(207, 0, 15, 0.8);
    margin-bottom: 8px;
}

div.error-view > div.error {
    font-family: monospace;
    font-size: 14px;
    margin-bottom: 8px;
    color: black;
}

div.error-view > div.error > span.name {
    font-weight: bold;
}

div.error-view > div.error > span.message {
    font-weight: normal;
}

div.error-view > table.stack-trace {
    /*
    background-color: #666;
    color: white;
    */
    background-color: #DDD;
    color: #666;

    border-radius: 3px;
    font-size: 10px;
    font-family: monospace;
    /* font-weight: bold; */
    padding: 8px;
}

/*
div.error-view > table.stack-trace td.function {
}
*/

div.error-view > table.stack-trace td.file {
    padding-left: 8px;
}

div.error-view > table.stack-trace td.file a {
    color: #114aaf;
}

div.error-view > table.stack-trace td.file a span.location {
    color: #107ce9;
}


div.error-view > span.message {
    font-weight: bold;
    color: red;
    font-size: 14px;
    font-family: monospace;
}