



div.error-handler {
    background-color: white;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
}

div.error-handler button.clear-button {
    position: fixed;
    bottom: 12px;
    right: 12px
}





