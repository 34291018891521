
@import '../../base';
@import '../../fonts';

ul.component-simple-list {
    /* padding: 4px 8px; */
    margin: 0px;
    padding: 0px;
}


ul.component-simple-list ul.component-simple-list {
    padding: 0px !important;
    /*
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    */
}



ul.component-simple-list > div.collapse {
    background-color: rgba(0,0,0,0.05);
    /*
    padding: 4px 0px;
    */
    /*
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    */
}





ul.component-simple-list > div.list-entry {

    /* 
    border: 1px solid red;
    */

    /*
    padding: 1em 1em;
    */

    /*
    border-radius: 3px;
    */

    height: 21px;
    max-height: 21px;
    min-height: 21px;

    /* margin-top: 8px; */
    /* padding: 3px 2px 3px 6px; */

    padding: 6px 2px 6px 8px;

    box-sizing: content-box;

    color: #444;
}


ul.component-simple-list > div.list-entry.open {
    /* border-radius: 5px; */
    background-color: rgba(0,0,0,0.05) !important;
    color: black !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-bottom: 1px dashed rgba(0,0,0,0.15); */
}

ul.component-simple-list > div.list-entry:hover {
    /*
    background-color: $BGCOLOR_A;
    color: white;
    background-color: #ecf0f1;
    background-color: #aed9e0;
    */
    color: black;
    background-color: rgba(0,0,0,0.1);

}

ul.component-simple-list > div.list-entry {
    border-radius: 0px;
}

/*
ul.component-simple-list > div.list-entry div.list-entry {
    border-radius: 0px;
}

ul.component-simple-list > div.list-entry:first-child,
ul.component-simple-list > div.list-entry:first-child div.list-entry:first-child {
    margin-top: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

ul.component-simple-list > div.list-entry:last-child,
ul.component-simple-list > div.list-entry:last-child div.list-entry:last-child {
    margin-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
*/

/*
ul.component-simple-list > div.list-entry > ul.component-simple-list > div.list-entry:first-child {
    border-radius: 0px;
}

ul.component-simple-list > div.list-entry > ul.component-simple-list > div.list-entry:first-child {
    border-radius: 0px;
}
*/

ul.component-simple-list > div.list-entry > div.item-icon {
    min-width: unset;
    /* border: 1px solid red; */
    margin-right: 4px;
    color: inherit;
}

/*
ul.component-simple-list > div.list-entry > div.item-icon > svg.icon {
}
*/

ul.component-simple-list > div.list-entry > div.item-icon > svg.icon.invisible {
    visibility: hidden;
}

ul.component-simple-list > div.list-entry > svg.submenu-toggle.invisible {
    visibility: hidden;
}

ul.component-simple-list > div.list-entry > div.item-text {
    margin-right: 16px;
}

ul.component-simple-list > div.list-entry > div.item-text > span {
    font: $SIMPLE_LIST_FONT;
    font-weight: 400;
}

ul.component-simple-list > div.list-entry.open > div.item-text > span {
    font: $SIMPLE_LIST_FONT;
    font-weight: 700;
}







/* sx={{ minWidth: 'unset' }} */

