



div.custom-set-filter {
    /* margin: 10px; */
    /* padding: 6px; */
    /* border: 1px solid red; */
    padding: 8px;
}

div.custom-set-filter > div.values {
    /*
    margin: 10px;
    padding: 6px;
    */
    /* border: 1px solid green; */

    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: flex-start
}



div.custom-set-filter > div.values > div.row {

    /*
    margin: 4px;
    padding: 2px;
    */
    padding: 4px;
    /* border: 1px solid blue; */

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;

    cursor: pointer;
}



div.custom-set-filter > div.values > div.row > div.checkbox-container {
}

div.custom-set-filter > div.values > div.row > div.checkbox-container > .checkbox {
    padding:0px;
}

div.custom-set-filter > div.values > div.row > div.value {
    font-weight: 700;
    color: #444;
    margin-left: 4px;
}

div.custom-set-filter > div.values > div.row > div.value > span.counts {
    font-weight: normal;
    color: #888;
    margin-left: 4px;
}







