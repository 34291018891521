
@import './fonts';

$BGCOLOR_A: #BCA;

// $CONTENT_BGCOLOR: #EEF4F9;
$CONTENT_BGCOLOR: #EEE;

$HEADER_OUTER_BORDER_COLOR: #888;
$HEADER_INNER_BORDER_COLOR: #AAA;

$TOOLBAR_BG_COLOR: #DDD;






@mixin label-index-background($fill, $fill-opacity) {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="2 2 20 20"%3E%3Cg fill="%23#{$fill}" fill-opacity="#{$fill-opacity}" stroke="none" %3E%3Cpath d="M12 3c7.2 0 9 1.8 9 9s-1.8 9-9 9s-9-1.8-9-9s1.8-9 9-9z"%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: center;
}








.signal-red {
    color: #cf000f;
}

.signal-green {
    color: #009944;
}









body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;    
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    box-sizing: border-box;
}

body, div#root {
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;

    background-color: #F0F0F0;
}

ul.MuiList-root {
    padding: 4px;
}

ul.MuiList-root li.MuiMenuItem-root {
    font-size: 12px;
    font-weight: bold;
    padding: 3px 8px;
    color: #444;
}



















/*
rgb(0, 153, 68)
rgb(207, 0, 15)

*/

/*
style={{ color: '#cf000f' }}
style={{  }}
*/



/*
div#page-layout > div.page-footer {
    background-color: $red !important;
}
*/